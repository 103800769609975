<template>
  <div>
    <div>{{ meta.question }}</div>
    <div
      data-test="content-qr-code-title"
      class="tt-black--text text--lighten-2"
    >
      Распечатать QR-код размером
    </div>
    <div data-test="content-qr-code-sizes">
      <template v-for="size in [5, 7, 10, 12, 15]">
        <a
          :key="size"
          class="text-decoration-none pr-3"
          :href="`https://api.qrserver.com/v1/create-qr-code/?size=${Math.round(size*37.5)}` +
            `x${Math.round(size*37.5)}&margin=${Math.round(size*2.5)}&data=${meta.validate.code}`"
          target="_blank"
          :data-test="`content-qr-code-size-${size}`"
        >
          {{ `${size}x${size}` }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentQrCode',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
